:root {
  --session-green: #00f782;
}

html {
  overflow-y: hidden;
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

body {
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  max-width: 100%;
  padding: 0px 8px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #000;
}

#content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  overflow: auto;
}

/* Weird issue with on safari where elements overlap each other */
#content > div {
  -webkit-flex: 0;
  flex-shrink: 0;
}

.color-black * {
  color: black;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.oxen-button {
  background: linear-gradient(93deg, #00f782 -7.18%, #00ffd1 97.66%);
  outline: none;
  border-radius: 24px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.oxen-button:disabled {
  color: black;
  background: linear-gradient(93deg, #00f782 -7.18%, #00ffd1 97.66%);
  outline: none;
  border-radius: 24px;
  opacity: 0.5;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.oxen-button:hover {
  filter: brightness(0.9);
}

#connect-wallet {
  background-blend-mode: overlay;
  color: var(--session-green);
  background: none;
  padding: 0;
}

#connect-wallet > p {
  margin: 0;
}

#background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../public/images/background.png');
  background-size: cover;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
}

.backgroundImageLoaded {
  opacity: 1;
}

.title {
  margin: 20px 0;
}

.textCenter {
  text-align: center;
}

.titleImage {
  object-fit: contain;
  width: 100%;
  max-width: 600px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
}

.titleImageLoaded {
  opacity: 1;
}

.links {
}

.links > a {
  color: white;
}

#grid {
  padding: 16px;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px;
  align-items: center;
}

a {
  text-decoration: none;
}

@media (max-width: 600px) {
  .titleContainer {
    max-height: 120px;
    height: auto !important;
  }

  .header-wrapper {
    flex-direction: column;
  }

  #web3-status-connected,
  .account-wrapper,
  .header-wrapper {
    width: 100%;
    min-width: 100%;
  }
}

@media (min-width: 600px) {
  .title {
    margin-top: 40px;
  }

  #grid {
    padding: 16px 1.5em;
  }
}

@media (min-width: 1280px) {
  .title {
    margin-top: 60px;
    margin-bottom: 0px;
  }

  #grid {
    padding: 16px 7.5em;
  }
}

@font-face {
  font-family: 'roboto-black';
  src: url('./assets/fonts/roboto-black-webfont.woff2') format('woff2'),
    url('./assets/fonts/roboto-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.readable {
  max-width: 650px;
  font-size: 0.98rem;
}

a {
  color: var(--session-green);
}
